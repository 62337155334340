//React Imports
import React, {useState, useEffect} from 'react';
import './AdcreatorAutoPage.style.scss';
//Components and utility function imports
import {AdCreatorForm} from '../../Components/form/adCreator-form/AdCreatorForm';
import {reportMessagesTo} from '../../socket';
import {handleDownloadClick} from './../../utils/handleDownloadClick';
import CampaignsGrid from '../../Components/ui/CampaignsGrid/campaignsGrid.component';
import {CustomButton} from '../../Components/form/Button/buttonComponents.component';
import mfaCallApi from '../../utils/mfaCallApi';
import {sessionId} from '../../Components/form/adCreator-form/AdCreatorForm';
import {createQueryString, parseQueryString} from '../../utils/urlUtils';
import AdDiv from '../../Components/ui/adDiv/adDiv.component';
import {AdEditor} from '../../Components/ui/AdEditor/AdEditor.component';
import AdModifyer from './../../Components/ui/AdModifyer/AdModifyer.component';
import {StageInfo} from '../../Components/ui/YellowBox/StageInfo';
import {myCurrentTime, isArrayEmpty, areAllEntriesEmpty} from '../../utils/helpers';
import loadFonts from '../../utils/loadFonts';
import {styles, themes} from './../../utils/dropdownValues';
import PropTypes from 'prop-types';
//Packages and library imports
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import uniqueRandom from 'unique-random';
import psl from 'psl';
import validator from 'validator';

const random = uniqueRandom(100, 1000000);
// These are not state variables and we do not want to re-render when they change.
// Re-rendering is triggered by updating the `waitingFor` message.
let numberOfActions = 0; // This is the number of actions that require a response from the server.
let numberOfResponses = 0; // This is the number of responses that have been received from the server.
let responses = [[]]; // This is a 2D array of objects, the same shape as the `createdAds` array.
let fullAutoState = 0; // This is the state number for the various states that full auto operation moves through.
let backStages = []; // This is an array of stage objects, each with a stageName and a startTime.
let totalCost = 0;
let updatingTemplateContainers = false;

const AdCreatorAuto = ({setLogOutPopUp}) => {
  const [waitingFor, setWaitingFor] = useState(''); // Announce what we are waiting for.
  const [campaignNames, setCampaignNames] = useState([]);
  const [formats, setFormats] = useState([]);
  const [createdAds, setCreatedAds] = useState([[]]);
  const [editableFields, setEditableFields] = useState([[]]);
  const [actions, setActions] = useState([[]]); // This is a 2D array of objects, the same shape as the `createdAds` array.
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorHeader, setErrorHeader] = useState('');
  const [iframeKeys, setIframeKeys] = useState([[]]);
  const [inhibitZipDownload, setInhibitZipDownload] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [editingCreatedAd, setEditingCreatedAd] = useState(null);
  const [modifyingAd, setModifyingAd] = useState(null);
  const [modifyingCampaign, setModifyingCampaign] = useState(null);
  const [promptEntries, setPromptEntries] = useState([[]]);
  const [stages, setStages] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [modifyAdResponses, setModifyAdResponses] = useState(0);
  const [fontsToLoad, setFontsToLoad] = useState([]);

  // const [isLoading, setIsLoading] = useState(true);
  // const initialRender = useRef(true); // Track initial render

  //STATES FOR ADCREATOR FORM
  const [formData, setFormData] = useState({
    numberOfCampaigns: 3,
    projectName: '',
    mfmProjectUrl: '',
    website: '',
    documents: [],
    targetMarket: '',
    targetAgeRange: '',
    textSearchDepth: 0,
    imageSearchDepth: 0,
    specialInstructions: '',
    style: [],
    theme: [],
    channel: [],
    formatsDigital: [],
    uuid: undefined,
    teamName: '',
    subTeamName: '',
    // teamId: '',
    containingCampaignIds: '',
    fetchTemplatesFrom: '',
    templateTeamName: '',
    language: 'English',
    applyBrandColors: true,
    multipleAdaptations: false,
    numberOfAdaptations: 0,
    referenceLayout: false,
  });

  // Function to fetch stage information
  const fetchStageInfo = async () => {
    console.log('currently trying to fetch stage info');
    const userToken = localStorage.getItem('id_token');
    const tokenExpiration = new Date(localStorage.getItem('token_expiration'));

    if (!userToken || new Date() > tokenExpiration) {
      setLogOutPopUp(true);
      return;
    }

    const projectId = parseQueryString('projectId') ?? undefined;

    if (projectId) {
      try {
        const response = await axios.get('https://qf4vhdxujb73yidgyneig366pi0reefw.lambda-url.us-east-1.on.aws/stageInfo/query', {
          headers: {authorization: userToken},
          params: {projectId},
        });

        if (response.status === 200) {
          const stageInfos = response.data || [];
          console.log('a successful fetch!');
          setStages((prevStages) => [...prevStages, ...stageInfos]);
        } else {
          throw new Error('Failed to fetch stage information');
        }
      } catch (error) {
        console.error('Error fetching stage information:', error);
      }
    }
  };

  // Function to post stage information
  const postStageInfo = async (latestStage) => {
    console.log('now trying to POST a stage');
    const userToken = localStorage.getItem('id_token');
    const tokenExpiration = new Date(localStorage.getItem('token_expiration'));

    if (!userToken || new Date() > tokenExpiration) {
      setLogOutPopUp(true);
      return;
    }

    const projectId = parseQueryString('projectId') ?? undefined;

    if (latestStage?.stageName) {
      try {
        const response = await axios.post(
          'https://qf4vhdxujb73yidgyneig366pi0reefw.lambda-url.us-east-1.on.aws/stageInfo/update',
          {
            projectId,
            stageName: latestStage.stageName,
            startTime: latestStage.startTime,
            endTime: latestStage.endTime,
            executionArn: latestStage.executionArn,
            detail: latestStage.detail,
          },
          {
            headers: {authorization: userToken},
            params: {projectId},
          }
        );

        if (response.status === 200) {
          console.log('Successfully posted stage info.');
        } else {
          throw new Error('Failed to post stage information');
        }
      } catch (error) {
        console.error('Error posting stage information:', error);
      }
    }
  };

  // Function to delete stage information
  const deleteStageInfo = async (projectId) => {
    const userToken = localStorage.getItem('id_token');
    const tokenExpiration = new Date(localStorage.getItem('token_expiration'));

    // Check if the token is valid
    if (!userToken || new Date() > tokenExpiration) {
      console.log('Token expired or not available');
      return;
    }

    try {
      console.log('trying to delete all stages for project ID');
      const response = await axios.delete(`https://qf4vhdxujb73yidgyneig366pi0reefw.lambda-url.us-east-1.on.aws/stageInfo/delete`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        params: {projectId},
      });

      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error deleting stage information:', error.response?.data || error.message);
    }
  };

  // Function to fetch teams user belong to
  const fetchTeamData = async () => {
    console.log('trying to fetch team data...');
    try {
      const response = await axios.get('https://lityg6l2vsbxkbh5qr4ti4alye0bkcfl.lambda-url.us-east-1.on.aws/');
      if (response.status === 200) {
        const sortedTeams = response.data.sort((a, b) => a.team.toLowerCase().localeCompare(b.team.toLowerCase()));
        // console.log(sortedTeams);
        setTeamOptions(sortedTeams);
      } else {
        console.error('Failed to fetch team data');
      }
    } catch (error) {
      console.error('Error fetching team data:', error);
    }
  };

  // Function to fetch any brandFonts we may need to apply
  const fetchFonts = async () => {
    console.log('Trying to fetch fonts...');
    const projectId = parseQueryString('projectId') ?? undefined;
    const cachedFonts = localStorage.getItem(`fonts_${projectId}`);

    if (cachedFonts) {
      console.log('fonts are cached...using those!');
      // console.log(JSON.parse(cachedFonts));
      setFontsToLoad(JSON.parse(cachedFonts));
    } else {
      const userToken = localStorage.getItem('id_token');
      const tokenExpiration = new Date(localStorage.getItem('token_expiration'));

      // Check if the token is valid
      if (!userToken || new Date() > tokenExpiration) {
        console.log('Token expired or not available');
        return;
      }

      if (projectId) {
        console.log('sending fetch request for fonts...');

        try {
          const response = await axios.post(
            'https://vobo5ezep4fjxjvpzoeptrlv640ifvox.lambda-url.us-east-1.on.aws/',
            {
              uuid: projectId,
              sections: ['brandFonts'],
            },
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response.status === 200) {
            const fonts = response.data.brandFonts;
            console.log('fonts fetched are...');
            console.log(fonts);
            localStorage.setItem(`fonts_${projectId}`, JSON.stringify(fontsToLoad));
            setFontsToLoad(fonts);
          }
        } catch (error) {
          console.error('Error fetching fonts:', error);
        }
      }
    }
  };

  // Fetch stages & teams user Belongs to on initial render
  useEffect(() => {
    fetchTeamData();
    fetchStageInfo();
    fetchFonts();
  }, []);

  // Load fonts whenever fontsToLoad updates
  useEffect(() => {
    console.log('the fonts to load have changed...reloading!');
    if (fontsToLoad.length > 0) {
      loadFonts(fontsToLoad);
    }
  }, [fontsToLoad]);

  //function to invoke error message
  const invokeErrorMessage = (errorHeader, errorMessage) => {
    setErrorHeader(errorHeader);
    setErrorMessage(errorMessage);
    setError(true);
  };
  //function to refresh iframe keys
  const setKey = (colIndex, rowIndex) => {
    setIframeKeys((prev) => {
      const newRefreshKeys = prev.map((col, i) => (i === colIndex ? col.map((row, j) => (j === rowIndex ? random() : row)) : col));

      return newRefreshKeys;
    });
  };

  //used for documents array validation, checks if document properties are valid for submission
  const isDocumentPropertyEmpty = (array) => {
    return array.some((obj) =>
      Object.entries(obj).some(([key, value]) => {
        // Check if the value is empty
        if (value === '' || value === null || value === undefined) {
          return true;
        }
        // Additional check for URLs
        if (key === 'url' && !validator.isURL(value)) {
          return true;
        }
        return false;
      })
    );
  };
  //function that resets state variables
  //cleared all actions back to an empty string:""
  const zapButtons = () => {
    setCreatedAds([[]]);
    setEditableFields([[]]);
    setCampaignNames([]);
    setFormats([]);
    setPromptEntries([[]]);
    // We must only look at actions in `setActions` or we may see the wrong value.
    setActions((actions) => {
      responses = Array.from({length: actions.length}, () => Array.from({length: actions[0].length}, () => undefined));

      const newActions = actions.map((outer) => {
        return outer.map((_inner) => {
          return {actions: ''};
        });
      });
      return newActions;
    });
  };

  // The `actions` array is constructed as the user makes choices
  // from the dropdowns in the button grid.
  // The actions are state variables because we set them programmatically
  // when doing FULL AUTO ad generation
  // and that has to update the display
  const makeEmptyActions = ({columns, rows}) => {
    // Create an array with `columns` number of elements,
    // each being an array with `rows` number of `undefined` values.
    const newActions = Array.from({length: columns}, () => Array.from({length: rows}, () => ({action: ''})));
    setActions((_prevActions) => {
      console.log(`makeEmptyActions:`, newActions);
      return newActions;
    });
    const newIframeKeys = Array.from({length: columns}, (_col, _colIndex) => Array.from({length: rows}, (_row, _rowIndex) => random()));
    setIframeKeys((_prevIframeKeys) => {
      console.log('IFRAME KEYS::', newIframeKeys);
      return newIframeKeys;
    });
    const newFields = Array.from({length: columns}, () =>
      Array.from({length: rows}, () => {
        return {};
      })
    );
    setEditableFields((_prevFields) => {
      console.log('NEW FIELDS::', newFields);
      return newFields;
    });
    const newPromptEntries = Array.from({length: columns}, () =>
      Array.from({length: rows}, () => {
        return {prompt: ''};
      })
    );
    setPromptEntries((_prevEntries) => {
      return newPromptEntries;
    });
    // Initialize the responses array with the same shape as the actions array.
    // Each element of the inner arrays is set to `undefined`.
    // We cannot call clearResponses() here because the `actions` will not be updated.
    responses = Array.from({length: columns}, () => Array.from({length: rows}, () => undefined));
  };

  // This function is called when the user clicks the Execute button.
  // Or the choose Templates button.
  // FULL AUTO operation actually clicks those buttons so that calls in here too.
  const startStage = (stageName, data) => {
    // Construct a new stage object and add it to the stages array.
    if (!stageName) {
      return;
    }
    const newStage = {stageName, startTime: myCurrentTime(), executionArn: data?.executionArn};
    if (data?.startDate) {
      newStage.startTime = data.startDate.replace('T', ' ').slice(0, 19);
    }
    backStages = [...backStages, newStage]; // force redraw
    setStages(backStages);
    setActions((actions) => {
      const flattenedActions = actions.flat();
      const counts = {};

      flattenedActions.forEach((obj) => {
        const action = obj?.action ?? ''; // Reference the action property of the object
        counts[action] = counts[action] ? counts[action] + 1 : 1;
      });

      // Assuming you want to remove entries with an empty action string:
      delete counts[''];

      // Convert the counts object into an array of [action, count] pairs
      const countsArray = Object.entries(counts);
      newStage.detail = countsArray.map(([action, count]) => `${action}:${count}`).join(', ');
      backStages = [...backStages]; // force redraw
      setStages(backStages);
      return actions;
    });
  };
  const endStage = () => {
    const s = myCurrentTime();
    if (backStages.length > 0) {
      backStages[backStages.length - 1].endTime = s;
    }
    const latestStage = backStages[backStages.length - 1];
    backStages = [...backStages]; // force redraw
    setStages(backStages);
    postStageInfo(latestStage);
  };
  //controls the x button on the stage box
  const dismissBox = () => {
    backStages = [];
    const projectId = parseQueryString('projectId') ?? undefined;
    deleteStageInfo(projectId);
    setStages(backStages);
  };

  const hasAdaptationData = (arrayOfObjects) => {
    // Loop through each object in the array
    for (const obj of arrayOfObjects) {
      // Check if the name is 'Adaptation Data' (case-insensitive) and url is a non-empty string
      if (obj.name.toLowerCase() === 'adaptation data' && typeof obj.url === 'string' && obj.url.trim() !== '') {
        return true; // Return true if conditions are met
      }
    }
    return false; // Return false if no matching object is found
  };
  const submitChooseTemplateCampaigns = async (e) => {
    e.preventDefault();
    // const access_token = localStorage.getItem('access_token');
    // const id_token = localStorage.getItem('id_token');
    const token_expiration = localStorage.getItem('token_expiration');
    const latestTime = new Date().getTime();
    // if (!access_token || !id_token || token_expiration) {
    //   return invokeErrorMessage('There is no token available, The user seems not to be signed In, please signIn');
    // }
    if (latestTime > token_expiration) {
      console.log('IT HAS EXPIRED!!!!!');
      return setLogOutPopUp(true);
    }
    if (formData?.documents?.length > 0 && isDocumentPropertyEmpty(formData?.documents)) {
      return invokeErrorMessage('Failed to submit Prompts', 'document entries cannot be submitted empty or wrong, please fill in the document entries correctly!!');
    }
    const errorChecks = [
      {
        condition: formData.numberOfAdaptations < 3,
        title: 'Number of adaptations',
        message: 'When creating multiple adaptations of an ad, you have to create more than 3 adaptations.',
      },
      {
        condition: formData.formatsDigital.length > 1,
        title: 'Format too long',
        message: 'When creating multiple adaptations of an ad, you can only select one format.',
      },
      {
        condition: !hasAdaptationData(formData.documents),
        title: 'Expected document',
        message: 'A valid document with the name Adaptation Data is expected in the list of documents.',
      },
    ];

    if (formData.multipleAdaptations) {
      for (const {condition, title, message} of errorChecks) {
        if (condition) {
          return invokeErrorMessage(title, message);
        }
      }
    }

    if (formData.referenceLayout) {
      numberOfActions = formData.numberOfCampaigns * formData.formatsDigital.length;
    }
    zapButtons(); // Clear out old data
    const containingCampaigns = formData?.containingCampaignIds
      ?.split(/[\s,]+/) // Split on commas and spaces
      .map((id) => id.trim())
      .filter((id) => id !== '');
    // We insert _id and prKey because the server expects them.

    const containingCampaignLength = containingCampaigns.length;
    if (containingCampaignLength) {
      setFormData((prev) => {
        return {...prev, numberOfCampaigns: containingCampaignLength};
      });
    }
    //added this so if we have an array that looks like this [''], we know we have picked by ai, so we clear it and make the array empty
    const style = containingCampaignLength
      ? styles.filter((style) => style.value.length > 0).map((style) => style.value)
      : formData.style.length === 1 && formData.style[0] === ''
      ? []
      : formData.style;
    const theme = containingCampaignLength
      ? themes.filter((theme) => theme.value.length > 0).map((theme) => theme.value)
      : formData.theme.length === 1 && formData.theme[0] === ''
      ? []
      : formData.theme;
    const numberOfCampaigns = containingCampaignLength ? +containingCampaignLength : +formData.numberOfCampaigns;
    // We insert _id and prKey because the server expects them.
    //, 'billboard', 'billboard', 'billboard', 'billboard', 'billboard', 'billboard', 'billboard', 'billboard', 'billboard', 'billboard'
    const bodyObject = {
      _id: 'none',
      prKey: 'none',
      numberOfCampaigns,
      formData: {
        ...formData,
        numberOfCampaigns,
        style,
        theme,
        containingCampaigns,
      },
      sessionId,
      source: 'form',
    }; // source is "form", "mfm" or "mfs"
    if (updatingTemplateContainers) {
      Object.assign(bodyObject, {options: {updatingTemplateContainers}});
    } // This alters the behaviour of the `listFields` action and the `chooseTemplateCampaigns` operation.

    setWaitingFor('chooseTemplateCampaigns POST response');
    try {
      //made a function for api calls since they almost have the same request route
      //check out mfaCallApi directory in util folder
      const response = await mfaCallApi('chooseTemplateCampaigns', bodyObject);

      setWaitingFor(`${!formData.referenceLayout ? 'chooseCompletion socket response' : 'reference layout response'}`);
      if (response.status !== 200) {
        return invokeErrorMessage('HTTP ERROR IN MFA-EXECUTE', 'There is no HTTP response back from MFA-EXECUTE ');
      } else {
        console.log('mfa-choose-template-campaigns-response', response?.data.data.executionArn);
        const queryString = createQueryString('projectId', formData.uuid) + '&' + createQueryString('tab', '2') + '&' + createQueryString('createdAd', 'yes');
        window.history.pushState({}, '', `${window.location.pathname}?${queryString}`);

        return startStage('Choose Template Campaigns', response.data.data);
      }
    } catch (err) {
      setWaitingFor('');
      console.log('NAME:', err.name, 'MESSAGE:', err.message, 'ERROR:', err);
      return invokeErrorMessage('Error making post Request to MFA API ', err.message);
    }
  };

  // This is the function called when we hit the Execute button.
  const submitMfaExecute = async (e) => {
    e?.preventDefault();
    // There is at least 1 second delay betwen updating `actions` and calling this function.
    // Updating `actions` triggers a re-render.
    // Re-render causes this function to be redefined, capturing a new value of `actions`.
    // So we do not have to execute this inside a setState.
    //check if we have campaignNames
    setError(false);
    numberOfActions = actions?.flat().filter((actionObject) => !!actionObject?.action).length;
    numberOfResponses = 0;

    // const access_token = localStorage.getItem('access_token');
    // const id_token = localStorage.getItem('id_token');
    const token_expiration = localStorage.getItem('token_expiration');
    const latestTime = new Date().getTime();
    // if (!access_token || !id_token) {
    //   return invokeErrorMessage('There is no token available, The user seems not to be signed In, please signIn');
    // }
    if (latestTime > token_expiration) {
      return setLogOutPopUp(true);
    }
    if (areAllEntriesEmpty(campaignNames)) {
      endStage();
      setWaitingFor('');
      return invokeErrorMessage('No Campaign Names Found', 'you did not receive any campaign names, so full auto could not continue/start');
    }
    if (numberOfActions === 0) {
      return invokeErrorMessage('FULL AUTO stopping', 'No more actions to execute');
    } else {
      setWaitingFor(`${numberOfActions} responses`);
      console.log('resetting responses');
      responses = Array.from({length: actions?.length ?? 1}, () => Array.from({length: actions?.[0].length ?? 0}, () => undefined));

      try {
        //added this so if we have an array that looks like this [''], we know we have picked by ai, so we clear it and make the array empty

        const style = formData.style.length === 1 && formData.style[0] === '' ? [] : formData.style;
        const theme = formData.theme.length === 1 && formData.theme[0] === '' ? [] : formData.theme;
        const bodyObject = {sessionId, _id: 'none', prKey: 'none', actions, formData: {...formData, style, theme}, source: 'form'};
        if (updatingTemplateContainers) {
          Object.assign(bodyObject, {options: {updatingTemplateContainers}});
        } // This alters the behaviour of the `listFields` action and the `chooseTemplateCampaigns` operation.
        console.log(`sending body:`, bodyObject);
        //made a function for api calls since they almost have the same request route
        //check out mfaCallApi directory in util folder
        const response = await mfaCallApi('execute', bodyObject);
        if (response.status !== 200) {
          return invokeErrorMessage('HTTP ERROR IN MFA-EXECUTE', 'There is no HTTP response back from MFA-EXECUTE ');
        } else {
          console.log('mfa-execute-response', response?.data.data.executionArn);
          return startStage('Execute', response.data.data);
        }
      } catch (err) {
        setWaitingFor('');
        console.log('NAME:', err.name, 'MESSAGE:', err.message, 'ERROR:', err);
        return invokeErrorMessage('Error making post Request to MFA API ', err.message);
      }
    }
  };

  // this function resets the action state variable
  const clearActions = () => {
    setActions((oldActions) => {
      const result = Array.from({length: oldActions?.length ?? 1}, () => Array.from({length: oldActions?.[0].length || 0}, () => ({action: ''})));
      return result;
    });
  };

  // In these functions, beware that we have to wait for createdAds to be updated.
  // We cannot reference the variable `createdAds` inside these functions because it is captured when the function is created.
  // So we have to use the callback form of setCreatedAds.
  // pretend to update it to get the updated value of createdAds.
  const selectExpandTemplate = () => {
    // Do not try to expand any templates that have not been chosen.
    // See note above on the use of the callback form of setCreatedAds.
    setCreatedAds((currentCreatedAds) => {
      setFormData((formData) => {
        console.log('formData', formData);
        setActions((oldActions) =>
          oldActions?.map((column, colIndex) =>
            column.map((row, rowIndex) => {
              const templateId = currentCreatedAds[colIndex]?.[rowIndex]?.template?._id;

              if (templateId) {
                if (!formData.multipleAdaptations) {
                  return {action: 'expandTemplate'};
                } else {
                  return rowIndex === 0 ? {action: 'expandTemplate'} : {action: ''};
                }
              } else {
                return {action: ''};
              }
            })
          )
        );
        return formData;
      });
      return currentCreatedAds;
    });
  };

  //used in fullAuto to set all the default templates to createAds
  const selectCreateAd = () => {
    // Do not try to create any templates that have not been expanded.
    // See note above on the use of the callback form of setCreatedAds.
    setCreatedAds((currentCreatedAds) => {
      setActions((oldActions) => oldActions?.map((column, colIndex) => column.map((row, rowIndex) => ({action: rowIndex === 0 && currentCreatedAds[colIndex]?.[rowIndex]?.url ? 'createAd' : ''}))));
      return currentCreatedAds;
    });
  };
  //used in fullAuto to set all other templates apart from the default templated to fillAd
  const selectFillAd = () => {
    // Do not try to fill the default templates or any templates that have not been expanded.
    // See note above on the use of the callback form of setCreatedAds.
    // Do not try to fillAd unless the default ad has been created.
    // See note above on the use of the callback form of setCreatedAds.
    setCreatedAds((currentCreatedAds) => {
      setFormData((formData) => {
        setActions((oldActions) => {
          return oldActions?.map((column, colIndex) => {
            //responses = Array.from({length: oldActions.length}, () => Array.from({length: oldActions[0].length}, () => undefined));
            return column?.map((row, rowIndex) => {
              if (rowIndex !== 0) {
                if (formData.multipleAdaptations) {
                  const shouldAdaptAd = currentCreatedAds[colIndex]?.[0]?.template?.adSize;
                  return {action: shouldAdaptAd ? 'adaptAd' : ''};
                } else {
                  const shouldFillAd = currentCreatedAds[colIndex]?.[rowIndex]?.url && currentCreatedAds[colIndex]?.[0]?.template?.adSize;
                  return {action: shouldFillAd ? 'fillAd' : ''};
                }
              } else {
                return {action: ''};
              }
            });
          });
        });
        return formData;
      });
      return currentCreatedAds;
    });
  };

  const submitAutoCreate = () => {
    setWaitingFor('Full Auto to start'); // This greys out the buttons.
    setStages([]); // Delete old stage messages.
    const projectId = parseQueryString('projectId') ?? undefined;
    deleteStageInfo(projectId);
    moveIntoFullAutoState(1);
  };

  const selectListFields = () => {
    // Do not try to create any templates that have not been expanded.
    // See note above on the use of the callback form of setCreatedAds.
    setCreatedAds((currentCreatedAds) => {
      setActions((oldActions) => oldActions?.map((column, colIndex) => column.map((row, rowIndex) => ({action: rowIndex === 0 && currentCreatedAds[colIndex]?.[rowIndex]?.url ? 'listFields' : ''}))));
      return currentCreatedAds;
    });
  };

  // This function runs when we press the "Auto tcChanger" button.
  const submitAutoTcChange = () => {
    setWaitingFor('Auto tcChange to start'); // This greys out the buttons.
    setStages([]); // Delete old stage messages.
    const projectId = parseQueryString('projectId') ?? undefined;
    deleteStageInfo(projectId);
    moveIntoFullAutoState(10);
  };

  const temporarilySetUpdatingTemplateContainers = () => {
    // This is a really crude way of signalling that we want updatingTemplateContainers to be sent to step functions.
    // What I don't want to happen is for this global variable to be set to true and then never set to false again, perhaps because of an error.
    // The timeout has to be long enough for the code to press a button and react to the button press.
    // Please don't tell anyone I write code like this.
    updatingTemplateContainers = true;
    setTimeout(() => {
      updatingTemplateContainers = false;
    }, 5000);
  };

  //function that selects id elements in the page and clicks them
  const pressButton = (buttonName) => {
    const selector = `#${buttonName}`;
    // WAIT for actions to settle after a state update before pressing the button
    setTimeout(() => {
      const button = document.querySelector(selector);
      if (button) {
        console.log(`pressButton: button "${selector}"`);
        button.click();
      } else {
        console.log(`ERROR: pressButton: button "${selector}" not found`);
      }
    }, 1000);
  };

  //used to create a new project based on aan already created project
  const makeNewProject = () => {
    const queryString =
      createQueryString('projectId', formData.uuid) + '&' + createQueryString('tab', '2') + '&' + createQueryString('createdAd', 'yes') + '&' + createQueryString('newProject', 'yes');
    const newTab = `${window.location.pathname}?${queryString}`;
    return window.open(newTab, '_blank');
  };
  // used to refresh iframeKeys
  const refreshIframeKeysFunction = (campaignNumber, deliverableNumber) => {
    const intervalDuration = 1000;
    const timeoutDuration = 2000;
    let num = 2000;
    console.log('REFRESHING IFRAME:::', campaignNumber, deliverableNumber);
    const updateKeys = () => {
      setKey(campaignNumber, deliverableNumber);
    };
    //we need to wait for the ad content to be ready before we can refresh the iframe.
    // We actually re-render thre times here, loading the iframe each time.
    //added this just to be sure we get the correct iframe as its content does not update on time
    const interval = setInterval(() => {
      num -= intervalDuration;
      if (num <= 0) {
        clearInterval(interval);
      }
      setTimeout(updateKeys, timeoutDuration);
    }, intervalDuration);
  };
  //used to extract campaign names after we receive a read project socket response
  const extractCampaignNames = (createdAds) => {
    const campaignNames = createdAds
      .flat()
      .map((creAds) => creAds?.template?.containingCampaign?.name)
      .filter((name) => name !== undefined);

    // console.log(campaignNames);
    return [...new Set(campaignNames)];
  };
  //primarily used to check result codes after created ads action, if the result codes on all the default ads are not equal to zero we return true and an error
  const checkResultCodes = (responses) => {
    // Loop through each entry's first element
    for (let response of responses) {
      if (response[0] !== null && response[0] !== undefined) {
        // Ensure there's an item to check
        // If resultCode is 0 for any entry, return false
        if (response[0]?.action?.resultCode === 0) {
          return false;
        }
      }
    }
    // If we've gone through all entries without returning false, return true
    return true;
  };
  //used to clear values in the actions
  const clearValues = (e) => {
    e.preventDefault();
    clearActions();
    // We are just hoping that `actions` has the right shape here.
    responses = Array.from({length: actions?.length ?? 1}, () => Array.from({length: actions?.[0]?.length ?? 0}, () => undefined));
  };

  // Executed on clicking the dismiss button of the error popup.
  const removeError = (e) => {
    e.preventDefault();
    setError(false);
    fullAutoState = 0;
  };

  // Executed when the refresh button for one iframe is pressed.
  const refreshIframe = (e) => {
    e.preventDefault();

    // Find the closest parent .template-div element
    const templateDiv = e.target.closest('.template-div');

    // Within the .template-div, find the iframe
    const iframe = templateDiv.querySelector('iframe');

    if (iframe) {
      const currentSrc = iframe.src;

      // Append a unique identifier to the URL to force-refresh the iframe
      const randomQueryString = `?rand=${uuidv4()}`; // Assuming uuidv4 is imported and available

      // Check if the current src has any query params, update accordingly
      iframe.src = currentSrc.includes('?') ? currentSrc.replace(/\?.*$/, randomQueryString) : `${currentSrc}${randomQueryString}`;

      console.log('Refreshed iframe:', iframe.src);
    }
  };

  //used to set a fileName based on formdata name or website name, if they exist
  const getFileName = () => {
    let title;
    if (formData?.projectName) {
      title = formData?.projectName?.replace(/[ /]/g, '_');
    } else if (formData?.website) {
      try {
        title = psl.get(new URL(formData?.website).hostname);
      } catch (error) {
        title = formData?.website;
      }
    }

    return title; // Add this line to return the title
  };

  const downloadZipFile = async (data, campaignNames, formats) => {
    setInhibitZipDownload(true);
    const title = getFileName();
    const request = {
      sourcePrefix: `templates/${data?.obscureString || ''}/`,
      zipFileName: `${title || ''}_${campaignNames}_${formats}_${data?.template?.adSize?.width}X${data?.template?.adSize?.height}.zip`,
    };
    await handleDownloadClick(request, setInhibitZipDownload); // shared download code
  };

  const downloadAllZipFiles = async (index) => {
    const definedItems = createdAds[index]?.filter((item) => !!item && item?.obscureString !== '' && item?.template?.adSize);
    if (!definedItems.length) {
      return invokeErrorMessage('PLEASE CREATE AN AD!!', 'You have to create or fill an ad to download the zip file');
    }
    const sourcePrefixes = definedItems.map((item) => `templates/${item?.obscureString || ''}/`);
    const title = getFileName();
    const combinedZipFileName = `${title || '_'}_${campaignNames?.[index] || 'Campaign'}.zip`;

    const zipFileNames = definedItems?.map((data) => {
      const formatIndex = +data?.obscureString?.split('/').pop();
      return `${campaignNames?.[index] || 'Campaign'}_${formats[formatIndex]}_${data?.template?.adSize?.width || 'width'}X${data?.template?.adSize?.height || 'height'}.zip`;
    });

    const request = {sourcePrefixes, zipFileNames, combinedZipFileName};
    setInhibitZipDownload(true);
    console.log('REQUEST BODY DOWNLOAD----', request);
    await handleDownloadClick(request, setInhibitZipDownload); // shared download code
  };

  // eslint-disable-next-line no-unused-vars
  const launchEdit = (data, campaignNumber, deliverableNumber) => {
    setEditingCreatedAd({campaignNumber, deliverableNumber}); // Cause edit buttons to be disabled, and the ad editor to be displayed on the correct ad.
    // Request the fields for the ad we are editing.
    setActions((oldActions) =>
      oldActions?.map((column, colIndex) => column.map((row, rowIndex) => (colIndex == campaignNumber && rowIndex == deliverableNumber ? {action: 'listFields'} : {action: ''})))
    );
    // Send the `execute` command.
    pressButton('execute');
    // The response comes back through the socket.
  };

  const launchModify = (campaignNumber, deliverableNumber) => {
    console.log('launch modify');
    setModifyingAd({campaignNumber, deliverableNumber});
  };
  const launchModifyAllCampaign = (campaignNumber) => {
    setModifyingCampaign({campaignNumber: campaignNumber});
  };
  const moveIntoFullAutoState = (state) => {
    console.log(`moveIntoFullAutoState: from ${fullAutoState} to ${state}`);
    fullAutoState = state === undefined ? fullAutoState + 1 : state;

    // The pointers to functions in teh setTimeout are captured at teh time teh setTimeout is defined, not at the
    // time the enclosed function runs. So they are very old, and can point to old data.
    setTimeout(() => {
      console.log(`moveIntoFullAutoState: ${fullAutoState}`);
      const s = myCurrentTime();
      console.log(`START AUTO STAGE:${fullAutoState}:${s}`);
      switch (fullAutoState) {
        case 1:
          setWaitingFor(''); // This enables the buttons.
          pressButton('chooseTemplateButton'); // `pressButton` includes a delay to allow the actions to settle.
          break;
        case 2:
          selectExpandTemplate();
          pressButton('execute');
          break;
        case 3:
          selectCreateAd();
          pressButton('execute');
          break;
        case 4: {
          console.log('####RESPONSES#####', responses, checkResultCodes(responses));
          if (checkResultCodes(responses)) {
            console.log('INSIDE CHECK RESULT CODE ERROR RESPONSE, RESULT:', checkResultCodes(responses));
            return invokeErrorMessage('Error: no ads created', 'full auto stopping early!!!');
          }
          selectFillAd();
          pressButton('execute');
          break;
        }
        case 5:
          fullAutoState = 0;
          break;
        // FullAutoState 10 and up are used for the templateContainer Changer
        case 10:
          setWaitingFor(''); // This enables the buttons.
          temporarilySetUpdatingTemplateContainers();
          pressButton('chooseTemplateButton'); // This will start step function with updatingTemplateContainers set to true.
          break;
        case 11:
          selectExpandTemplate();
          pressButton('execute'); // No need for updatingTemplateContainers to be set here.
          break;
        case 12:
          selectListFields();
          temporarilySetUpdatingTemplateContainers();
          pressButton('execute'); // This will start step function with updatingTemplateContainers set to true.
          break;
        case 13:
          fullAutoState = 0;
          break;
        default:
          setTimeout(() => {}, 5000);
          console.log(`BAD STATE: moveIntoFullAutoState: ${fullAutoState}`);
          break;
      }
    }, 2000);
  };

  const receiver = ({message}) => {
    const t = myCurrentTime();
    if (message && typeof message === 'object') {
      switch (message.status) {
        case 'chooseComplete': {
          console.log(`chooseCompletion object received`, message);

          setCampaignNames(message.campaignNames);
          setFormats(message.formats);
          setCreatedAds(message.createdAds);
          const processedFormData = message.processedFormData;

          if (processedFormData) {
            //console.log('processed formData:::', processedFormData);
            setFormData((prev) => {
              return {...prev, ...processedFormData};
            });
          }
          responses = Array.from({length: message.campaignNames?.length ?? 1}, () => Array.from({length: message.formats?.length ?? 0}, () => undefined));
          // We now know the shape of the `createdAds` array.
          // Make an empty `actions` array, the same shape as the `createdAds` array.
          makeEmptyActions({columns: message.campaignNames?.length ?? 1, rows: message.formats?.length ?? 0});
          endStage();
          setWaitingFor('');
          if (fullAutoState) {
            moveIntoFullAutoState();
          }
          break;
        }
        case 'readProjectComplete': {
          console.log('WE ARE IN READ PROJECTS');

          if (message.resultCode != 0) {
            setWaitingFor('');
            console.log('MESSAGE IN ERROR BLOCK------', message);
            return invokeErrorMessage(`Error at ${new Date().toLocaleTimeString()}`, `ResultCode:${message.resultCode}\n resultReason:${message.resultReason}\n`);
          }
          const newProjectQuery = parseQueryString('newProject');
          console.log('NEW PROJECT QUERY::::::', newProjectQuery);
          const readProject = message?.executable?.project;
          console.log('READ PROJECTSSS', readProject);
          const storedFormData = readProject?.formData;
          let storedFormatsDigital = storedFormData.formatsDigital || [];
          const readProjectCreatedAds = readProject?.createdAds;

          if (Array.isArray(readProjectCreatedAds) && !isArrayEmpty(readProjectCreatedAds)) {
            // const readProjectFormats = extractFormats();
            if (!newProjectQuery || newProjectQuery === 'no') {
              setCreatedAds(readProjectCreatedAds);
              //we check if the formData has multipleAdaptations in it, if it does then we need to create multiple formats for it, since when selecting multiple adaptations it only has one format, and we are able to construct this, using the number of adaptations that wee requested for
              console.log('FORMATS DIGITAL IN READ PROJECTS', storedFormData.formatsDigital);
              if (storedFormData.multipleAdaptations && storedFormData.numberOfAdaptations) {
                storedFormatsDigital = Array.from({length: storedFormData.numberOfAdaptations}, () => {
                  return storedFormData.formatsDigital[0];
                });
              }
              const readProjectFormats = ['default', ...storedFormatsDigital];
              setFormats(readProjectFormats);

              const readProjectCampaignNames = extractCampaignNames(readProjectCreatedAds);

              setCampaignNames(readProjectCampaignNames);

              responses = Array.from({length: readProjectCampaignNames?.length ?? 1}, () => Array.from({length: readProjectFormats?.length ?? 0}, () => undefined));
              // We now know the shape of the `createdAds` array.
              // Make an empty `actions` array, the same shape as the `createdAds` array.
              makeEmptyActions({columns: readProjectCampaignNames?.length ?? 1, rows: readProjectFormats?.length ?? 0});
              //POPULATE FIELDS
            }
            //SET FORMDATA WITH FIELDS:
            let newFormData = {};

            setFormData((_prev) => {
              const uuid = newProjectQuery === 'yes' ? uuidv4() : storedFormData.uuid;
              newFormData = {...storedFormData, uuid: uuid};

              const queryString = createQueryString('projectId', uuid) + '&' + createQueryString('tab', '2') + '&' + createQueryString('createdAd', newProjectQuery === 'yes' ? 'no' : 'yes');
              window.history.pushState({}, '', `${window.location.pathname}?${queryString}`);
              return newFormData;
            });
          } else {
            // console.log('NO CREATE ADS HERE!!');
            invokeErrorMessage('NO CREATED ADS!!', 'This project you submitted to be read currently has no created ads !!');
          }
          setWaitingFor('');

          break;
        }
        case 'deliverableComplete':
          console.log('deliverableCompletion object received', message);
          {
            const {campaignNumber, deliverableNumber} = message;
            responses[campaignNumber][deliverableNumber] = message;
            // Do not update `createAds` if there was an error.
            // Or if no `createAd` was returned.

            if (message?.fieldInfo) {
              console.log('HERE ARE THE FIELDS:::::', editableFields);

              const updatedFields = message?.fieldInfo?.fieldInfos.map((fi) => {
                const {/*height, width, tag,*/ ...rest} = fi;

                if (fi?.id?.includes('sd_img')) {
                  return {...rest, imageUrl: fi.src};
                } else if (fi?.id?.includes('sd_bgcolor') || fi?.id?.includes('sd_txt')) {
                  return {...rest, text: fi.content};
                } else if (fi?.id?.includes('sd_btn')) {
                  return {...rest, text: fi.cta, href: fi.href};
                } else {
                  return {...rest, style: {}};
                }
              });

              const fieldInfos = {...message.fieldInfo, fieldInfos: updatedFields};
              console.log('WE HAVE received the fields', fieldInfos);

              setEditableFields((prev) => {
                const newFields = [...prev]; // A new array containing the old column arrays.
                newFields[campaignNumber] = [...prev[campaignNumber]]; // A new column array contining the old column objects.
                newFields[campaignNumber][deliverableNumber] = fieldInfos; // A new column object.
                return newFields;
              });
            }
            if (message.createdAd && !message.action?.resultCode) {
              setCreatedAds((prev) => {
                const newCreatedAds = [...prev]; // A new array containing the old column arrays.
                newCreatedAds[campaignNumber] = [...prev[campaignNumber]]; // A new column array contining the old column objects.
                newCreatedAds[campaignNumber][deliverableNumber] = message?.createdAd; // A new column object.
                return newCreatedAds;
              });
            }
            /////
            refreshIframeKeysFunction(campaignNumber, deliverableNumber);
          }
          numberOfResponses += 1;
          console.log(`numberOfResponses: ${numberOfResponses} numberOfActions: ${numberOfActions}`);
          if (numberOfResponses === numberOfActions) {
            setWaitingFor('actionsCompletion socket response');
          } else {
            setWaitingFor(`${numberOfActions - numberOfResponses} responses`);
          }

          break;
        case 'deliverableModified': {
          const {campaign, deliverable} = message;
          console.log('message:::::', message);
          console.log('MODIFED AD RESPOONSES::::', modifyAdResponses);
          setModifyAdResponses((prev) => {
            const newResponse = prev - 1;
            setWaitingFor(`received response for campaign at position: ${campaign}/${deliverable} waiting for ${newResponse} modified responses`);
            return newResponse;
          });
          refreshIframeKeysFunction(campaign, deliverable);
          break;
        }
        case 'modifiedAllDeliverables': {
          console.log('modifiedAllDeliverables:::', message);
          endStage();
          setWaitingFor('');
          break;
        }
        case 'actionsComplete': {
          numberOfResponses = 0;
          numberOfActions = 0;
          console.log('actions-Complete Socket Response', message);
          endStage();
          setWaitingFor('');
          const referenceLayout = message.referenceLayout ? message.referenceLayout : false;
          // console.log('Received message:::', message);
          if (fullAutoState && !referenceLayout) {
            moveIntoFullAutoState();
          }
          break;
        }
        case 'token': {
          console.log('TOKEN received:', message.tokens);
          // Show the time of the error in the error message.
          let cost = (message.tokens.total / 1000) * 0.01;
          totalCost += cost;
          console.log(`COST OF INDIVIDUAL CHATGPT AD CREATION: $${parseFloat(cost.toFixed(6))}`);
          console.log(`COST OF TOTAL CHATGPT AD CREATION: $${parseFloat(totalCost.toFixed(6))}`);
          break;
        }

        case 'fontsToLoad': {
          console.log('attempting to load google fonts...');
          const {fonts} = message;
          if (fonts && fonts.length > 0) {
            loadFonts(fonts);
          }
          break;
        }

        case 'error':
          console.log('ERROR: receiver: error message:', message);
          // Show the time of the error in the error message.
          setWaitingFor('');
          return invokeErrorMessage(`Error at ${t}`, `ResultCode:${message.resultCode}\n resultReason:${message.resultReason}\n`);

        default:
          //read here-----------
          setWaitingFor('');
          console.log(`Unrecognized message object from socket: `, message);
          invokeErrorMessage(`unknown Message received from the socket at:${t}`, `${JSON.stringify(message)}`);
          break;
      }
    } else {
      //setBack to true after message comes back
      console.log(`message string from socket: ${message}`);
    }
  };

  // Apparently this code  may be run twice in development mode.
  useEffect(() => {
    // This function will run exactly once after the component is mounted
    // Tell the socket code where to send the messages.
    const runOnceFunction = () => {
      console.log('runOnceFunction: setting receiver');
      reportMessagesTo({receiver});
    };
    runOnceFunction();
  }, []); // Empty dependency array ensures this useEffect runs only once

  return (
    <>
      <div className="announcement-area">
        {stages.length > 0 && (
          <div className="announcement-box ">
            <button className="dismiss-button" onClick={dismissBox} style={{fontSize: '16px', padding: '0px'}}>
              ✖️
            </button>
            {stages.map((stage, index) => {
              return <StageInfo {...{stage}} key={index} />;
            })}
          </div>
        )}

        {waitingFor ? (
          <div className="announcement-box">
            waiting for:
            <br />
            {waitingFor}
          </div>
        ) : null}
      </div>{' '}
      {error ? (
        <div style={{textAlign: 'center'}}>
          <div className="error-content">
            <div className="error-head">
              {errorHeader}
              <b className="remove-error" onClick={removeError}>
                X
              </b>
            </div>
            <div className="error-body">{errorMessage}</div>
          </div>
        </div>
      ) : null}
      <div className="adcreatorpage-container">
        <AdCreatorForm
          {...{
            waitingFor,
            setWaitingFor,
            invokeErrorMessage,
            inhibitZipDownload,
            formData,
            setFormData,
            submitChooseTemplateCampaigns,
            tabValue,
            setTabValue,
            createdAds,
            startStage,
            teamOptions,
          }}
        />

        {tabValue === '2' && (
          <>
            <div className="addnewDoc-box"></div>
            <div className="button-container">
              {' '}
              <CustomButton className={waitingFor || inhibitZipDownload ? 'custom-button-disabled' : ''} onClick={submitAutoCreate} disabled={!!waitingFor || inhibitZipDownload}>
                Full Auto
              </CustomButton>
              <CustomButton
                className={waitingFor || inhibitZipDownload ? 'custom-button-disabled' : ''}
                onClick={() => {
                  pressButton('chooseTemplateButton');
                }}
                disabled={!!waitingFor || inhibitZipDownload}
              >
                Choose Template Campaigns
              </CustomButton>
            </div>
          </>
        )}
        <CampaignsGrid {...{campaignNames, formats, createdAds, actions, setActions, responses, invokeErrorMessage, editableFields, setEditableFields, refreshIframeKeysFunction}} />
        <div>
          {responses.length > 0 ? (
            <div>
              {!isArrayEmpty(createdAds) && (
                <div>
                  <div className="top-left">
                    <button className="add-document-button" onClick={makeNewProject}>
                      Make a new project
                      <br />
                      with this data
                    </button>
                  </div>
                  <div className="top-left">
                    <button className="add-document-button" style={{marginTop: '80px'}} onClick={submitAutoTcChange}>
                      Auto
                      <br />
                      tcChanger
                    </button>
                  </div>
                  <div className="execute-container">
                    <CustomButton className={waitingFor || inhibitZipDownload ? 'custom-button-disabled' : ''} onClick={submitMfaExecute} disabled={!!waitingFor || inhibitZipDownload} id="execute">
                      Execute
                    </CustomButton>
                    <CustomButton className={waitingFor || inhibitZipDownload ? 'custom-button-disabled' : ''} onClick={clearValues} disabled={!!waitingFor || inhibitZipDownload}>
                      Clear
                    </CustomButton>
                  </div>
                  <div className=""></div>
                </div>
              )}

              <div className="ad-container">
                {createdAds?.map((outer, i) => {
                  return outer?.map((createdAd, j) => {
                    return (
                      <div className="template-div" key={j}>
                        {}
                        {j === 0 && (
                          <>
                            <div className="zip-container">
                              <hr />
                              <p>Campaign: {campaignNames[i]}</p>
                              <div className="header-actions">
                                <button
                                  className={waitingFor || inhibitZipDownload ? 'download-zip-button-disabled' : 'download-zip-button'}
                                  disabled={inhibitZipDownload || !!waitingFor}
                                  onClick={() => downloadAllZipFiles(i)}
                                >
                                  Download combined zipfile
                                </button>
                                <button
                                  className={waitingFor || inhibitZipDownload ? 'download-zip-button-disabled' : 'download-zip-button'}
                                  disabled={inhibitZipDownload || !!waitingFor}
                                  onClick={() => launchModifyAllCampaign(i)}
                                >
                                  Modify-All-Campaigns
                                </button>
                              </div>
                              {modifyingCampaign?.campaignNumber === i && (
                                <>
                                  <AdModifyer
                                    {...{
                                      setModifyingAd,
                                      campaignIndex: i,
                                      deliverableIndex: j,
                                      promptEntries,
                                      setPromptEntries,
                                      createdAds,
                                      waitingFor,
                                      setWaitingFor,
                                      startStage,
                                      invokeErrorMessage,
                                      wholeCampaign: true,
                                      setModifyingCampaign,
                                      setModifyAdResponses,
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </>
                        )}
                        <p className={`${createdAd?.template?.adSize ? 'template-heading-bold' : 'template-heading-normal'}`}>
                          <b>{`${i}/${j}`}</b>{' '}
                          {`${campaignNames[i] || 'Campaign'} ${formats[j]} ${
                            createdAd?.template?.adSize ? `${createdAd?.template?.adSize?.width}x${createdAd?.template?.adSize?.height}` : 'unknown_size'
                          }`}
                        </p>{' '}
                        {createdAd?.template?.adSize && (
                          <>
                            {' '}
                            <button className="refresh-button" onClick={refreshIframe}>
                              Refresh &#10227;
                            </button>
                            <button
                              className={inhibitZipDownload || waitingFor ? 'download-zip-button-disabled' : 'download-zip-button'}
                              onClick={() => downloadZipFile(createdAd, campaignNames[i], formats[j])}
                              disabled={inhibitZipDownload || waitingFor}
                            >
                              Download Zipfile
                            </button>
                            <button
                              className={editingCreatedAd || waitingFor ? 'download-zip-button-disabled' : 'download-zip-button'}
                              onClick={() => launchEdit(createdAd, i, j)}
                              disabled={editingCreatedAd || waitingFor}
                            >
                              Edit
                            </button>
                            <button className={waitingFor ? 'download-zip-button-disabled' : 'download-zip-button'} onClick={() => launchModify(i, j)} disabled={waitingFor}>
                              Modify
                            </button>
                          </>
                        )}
                        <AdDiv key={iframeKeys?.[i]?.[j]} createdAd={createdAd} />;
                        {editingCreatedAd?.campaignNumber == i && editingCreatedAd?.deliverableNumber == j && (
                          <AdEditor {...{formData, setFormData, setEditingCreatedAd, createdAd, editableFields, campaignNumber: i, deliverableNumber: j}} />
                        )}
                        {/* setModifyingAd, campaignIndex, deliverableIndex, promptEntries, setPromptEntries */}
                        {modifyingAd?.campaignNumber === i && modifyingAd?.deliverableNumber === j && modifyingCampaign?.campaignNumber !== i && (
                          <>
                            <AdModifyer
                              {...{
                                setModifyingAd,
                                campaignIndex: i,
                                deliverableIndex: j,
                                promptEntries,
                                setPromptEntries,
                                createdAds,
                                waitingFor,
                                setWaitingFor,
                                startStage,
                                invokeErrorMessage,
                                setModifyAdResponses,
                              }}
                            />
                          </>
                        )}
                      </div>
                    );
                  });
                })}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};
AdCreatorAuto.propTypes = {
  setLogOutPopUp: PropTypes.func,
};
export {AdCreatorAuto}; // Make sure the export matches the imported component
