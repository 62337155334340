const loadFonts = (fontsToLoad) => {
  fontsToLoad.forEach((font) => {
    const {url, styling} = font;
    const fontFamily = styling.fontFamily;
    const fontWeight = styling.fontWeight;
    const fontStyle = styling.fontStyle;

    // Create @font-face rule
    const fontFace = `
      @font-face {
        font-family: ${fontFamily};
        src: url('${url}');
        font-weight: ${fontWeight};
        font-style: ${fontStyle};
      }
    `;

    // Append the style to the document head
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(fontFace));
    document.head.appendChild(styleElement);
  });
};

export default loadFonts;
